import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store,{persistor} from '../src/store/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
      <Provider store={store} >
          <PersistGate persistor={persistor}>
              <SnackbarProvider maxSnack={1}>
    <App />
              </SnackbarProvider>
          </PersistGate>
      </Provider>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
