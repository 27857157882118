import React from 'react';
import {
    RouteProps,
    PathRouteProps,
    Navigate
} from "react-router-dom";
import {Login} from '../pages/Login/login'

interface IRedirectIfAuthenticated extends PathRouteProps {
    readonly isAuthenticated: boolean
    readonly redirectPath: string
}
const RedirectIfAuthenticated: React.FC<IRedirectIfAuthenticated> =
    ({
         isAuthenticated,
         ...props
     }) => {
        return isAuthenticated ?<Navigate to={props.redirectPath} /> : <Login />;
    };
export default RedirectIfAuthenticated;
