import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import {RootState} from '../rootReducer'
import {IPaymentTypeState} from "../../models/paymentTypes";
import {openNotifier} from "../notifier/notifier";
const api = process.env.REACT_APP_API_URL
export const initialStatePaymentTypes: IPaymentTypeState = {
    paymentTypes:[],
    currencies:[],
    loading: false,

}

export const getPaymentTypes = createAsyncThunk<string[],
    { },
    {
        rejectValue: unknown
    }>(
    'paymetTypes/getPaymentTypes',
    async (data, thunkApi) => {
        try {
            // @ts-ignore
            const response = await axios.get(`${api}/getPaymentTypes`);
            // @ts-ignore
            // thunkApi.dispatch(openNotifier({open:true,variant:"success",message:`Welcome ${response.data?.user?.strNombre}`}))
            return response.data;
        } catch (err) {
            // @ts-ignore
            console.log(err?.response?.data?.error)
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message??'Problema de conexión, Contacte a soporte'}))
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const getCurrencies = createAsyncThunk<string[],
    { },
    {
        rejectValue: unknown
    }>(
    'paymetTypes/getCurrencies',
    async (data, thunkApi) => {
        try {
            // @ts-ignore
            const response = await axios.get(`${api}/getCurrencies`);
            // @ts-ignore
            // thunkApi.dispatch(openNotifier({open:true,variant:"success",message:`Welcome ${response.data?.user?.strNombre}`}))
            return response.data;
        } catch (err) {
            // @ts-ignore
            console.log(err?.response?.data?.error)
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message??'Problema de conexión, Contacte a soporte'}))
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const paymentTypesSlice = createSlice({
    initialState:initialStatePaymentTypes,
    name: 'paymentTypes',
    extraReducers: builder => {
        builder
            .addCase(getPaymentTypes.pending, state => {
                state.loading = true
            })
            .addCase(getPaymentTypes.fulfilled, (state, {payload}) => {
                state.loading=false
                // @ts-ignore
                state.paymentTypes=payload?.data
            })
            .addCase(getPaymentTypes.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(getCurrencies.pending, state => {
                state.loading = true
            })
            .addCase(getCurrencies.fulfilled, (state, {payload}) => {
                state.loading=false
                // @ts-ignore
                state.currencies=payload?.data
            })
            .addCase(getCurrencies.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {},
})

export const getPaymentSelector = (state: RootState) => state.paymentType;
export const PaymentTypesReducer = persistReducer({
    storage:storageSession,
    key: 'paymentTypes',
    blacklist:['loading']
}, paymentTypesSlice.reducer)
