import './App.css';
import React from 'react';
import {RoutesComponent} from './routes/routes';
import {ModalComponent} from "./components/modal/modal";
import {ModalForm} from "./components/modalForm/modalForm";
import BackdropComponent from "./components/backdrop/backdrop"
function App() {

    return (
        <>
            <ModalComponent/>
            <ModalForm/>
            <BackdropComponent/>
            <RoutesComponent></RoutesComponent>
        </>
    );
}

export default App;
