import {Button, FormControl, IconButton, InputAdornment, TextField} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Logo} from '../../images'
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import { useAppDispatch,useSelector } from '../../store/store';
import {useFormik} from 'formik';
import { useNavigate } from "react-router-dom";
import {AxiosError} from "axios";
import {ILogin} from "../../models/login";
import {authSelector, auth} from "../../store/auth/auth";
import {openNotifier} from "../../store/notifier/notifier";
import {unwrapResult} from "@reduxjs/toolkit";
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#fff',
    },
    '.MuiInput-input':{color: '#fff'},
    '.MuiInputBase-root': {
        // borderBottomColor: 'green',
        color: '#fff',
        fontSize:'20px',
        fontFamily:'Ashley',
        '&:focus':{borderBottomColor:'#f18',}
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#783dbe',
    },
    '.MuiInputBase-input-MuiInput-input,.MuiFormLabel-root ':{
        color:'#fff !important'
    },
    '.MuiInputBase-root:before':{
        borderBottomColor:'#fff',

    }
});


export const Login=()=> {
    useEffect(() => {
        setTimeout(() => {
            setClass(true)
            setClass2(true)
        }, 2000);
    },[]);
    const [LogoClass,setClass]=useState(false);
    const [FormClass,setClass2 ]=useState(false);
    const dispatch = useAppDispatch();
    const navigate=useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {loading} = useSelector(authSelector);
    const {
        setFieldValue,
        setValues,
        values,
        handleChange,
        submitForm,
        handleBlur,
        setSubmitting
    } = useFormik<ILogin>({
        initialValues: {username:'',password:''},
        onSubmit: (values: ILogin, formikHelpers) => {
            dispatch(auth({password:values.password,username:values.username})).then(unwrapResult).then((result: any) => {
                navigate("../home", {replace: true})
            }).catch((result: AxiosError) => {
                //@ts-ignore
            })
        },
    })
    return (

        <div className="App">
            <header className="App-header bg-gradiant">
                <img className={LogoClass?"upZoom":'logoLogin'}  src={Logo} alt="logo" />
                <div className={FormClass?"upSlide":'FormLogin'}>
                    <h5 className="AshleyRegular">Ingrese los datos correctamente</h5>
                    <div>
                        <div>
                            <FormControl variant="standard" className="w340">
                                <CssTextField
                                    id="username"
                                    name={"username"}
                                    label="Ingrese su usuario"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.username}
                                    variant="standard" />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl variant="standard" className="w340">
                                {/*<InputLabel htmlFor="standard-adornment-password">Password</InputLabel>*/}
                                <CssTextField
                                    label="Contraseña"
                                    id="password"
                                    name={"password"}
                                    type={showPassword?'text':'password'}

                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    variant="standard"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={()=>setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ?  <VisibilityOffIcon/>: <VisibilityIcon/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </div>
                        <Button
                            variant="contained"
                            onClick={() => submitForm()}
                            disabled={loading}
                            size="large"
                            className="btn-login"
                        >Iniciar Sesión</Button>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Login;
