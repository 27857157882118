import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import AuthenticateRoute from './authenticateRoute';
import {authSelector} from '../store/auth/auth';
import RedirectIfAuthenticated from './redirectIfAuthenticateRoute';
import {useSelector} from 'react-redux';
import {LayoutAuthenticated} from '../components/layout/layoutAuthenticated';
import Home from "../pages/Home/home";
import Sale from "../pages/Sales/sale";
import PrevSale from "../pages/PrevSales/PrevSale";
import useNotifier from '../components/notifier/notifier';
export const RoutesComponent = () => {
    useNotifier();
    const {access_token} = useSelector(authSelector);
    return <BrowserRouter>
        <Routes>
            <Route
                path={''}
                element={
                    <Navigate to={"login"}/>
                }>
            </Route>
        <Route path='login'
               element={<RedirectIfAuthenticated
                   path={'/login'}
                   redirectPath={'/home'}
                   isAuthenticated={access_token}
               />}>
        </Route>
        <Route path='home'
               element={<AuthenticateRoute
                   path={'/home'}
                   layout={LayoutAuthenticated}
                   component={Home}
                   authenticatePath={'/login'}
                   isAuthenticated={access_token}
               />}>
        </Route>
            <Route path='sales'
                   element={<AuthenticateRoute
                       path={'/sales'}
                       layout={LayoutAuthenticated}
                       component={Sale}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}>
            </Route>
            <Route path='verify-sales'
                   element={<AuthenticateRoute
                       path={'/verify-sales'}
                       layout={LayoutAuthenticated}
                       component={PrevSale}
                       authenticatePath={'/login'}
                       isAuthenticated={access_token}
                   />}>
            </Route>
        </Routes>
    </BrowserRouter>
}
