import React,{FC} from "react"
import {Divider, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {MoodBadOutlined, MoodOutlined} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {changeStateModal,changeText,ModalSelector} from "../../store/modal/modal";
import {setInfoReservation} from "../../store/reservation/reservation";
import {useAppDispatch} from "../../store/store";

interface IReservation{
    index:number,
    // status:'no-show'|'checking'|'pending',
    status:string,
    adult:number,
    infant:number,
    child:number,
    package_tour:string,
    name:string,
    id:number,
    payment_method:string,
    balance:string,
    comentarios:string,
    canalDistribucion:string
}
export const Reservation:FC<IReservation>=({index,status,adult,comentarios,canalDistribucion,
                                            child,infant,payment_method,package_tour,
                                               name,balance,id
})=> {
    const modalSelector = useSelector(ModalSelector);
    const dispatch = useAppDispatch();
    const today=new Intl.DateTimeFormat('es-ES', { weekday: "short",
        day: "2-digit",
        month: "short",
        year: 'numeric'
    }).format(new Date());

    return                     <div className="mt-1" key={index} id={'id'+index}>
      <TableContainer className={status==='1'?'contItem checking':(status==='3'?'noShow contItem':'contItem')}>
          <Table aria-label="a dense table">
              <TableBody>
                  <TableRow>
                      <TableCell align="right" className="indicador">
                          <div className="grid-1">
                              <div><span>{index}</span></div>
                          </div>
                      </TableCell>
                      <TableCell align="left">
                          <div className="grid-2">
                              <div className="nombreTour">
                                  <Typography variant="h6">{name}</Typography>
                              </div>
                              <div>
                                  <span className="strong">{package_tour}</span>
                              </div>

                              <div>
                                  <span className="regular">Agencia:</span>
                                  <span className="strong">
                                        {canalDistribucion}
                                  </span>
                              </div>
                              <div>
                                  <span className="regular">Comentario:</span>
                                  <span className="strong">
                                        {comentarios}
                                  </span>
                              </div>
                          </div>
                      </TableCell>
                      <TableCell className="tdPax">
                          <div className="grid-3">
                              <div className="DFlex">
                                  <div>
                                      <span className="regular">Adultos:</span>
                                      <span className="strong f20">{adult}</span>
                                  </div>
                                  <div>
                                      <span className="regular">Niños:</span>
                                      <span className="strong f20">{child}</span>
                                  </div>
                                  <div>
                                      <span className="regular">Infantes:</span>
                                      <span className="strong f20">{infant}</span>
                                  </div>
                              </div>
                              <Divider orientation="horizontal" flexItem/>
                              <div className="DFlex">
                                  <div>
                                      <span className="regular">Metodo de Pago:</span>
                                      <span className="strong f20">{payment_method}</span>
                                  </div>
                                  <div>
                                      <span className="regular">Balance:</span>
                                      <span className="strong f20">{balance}</span>
                                  </div>
                              </div>
                          </div>
                      </TableCell>
                      <TableCell align="right">
                          <div className="grid-4">
                              <div className="btnItemHappy" onClick={()=>{
                                  dispatch(changeStateModal(true))
                                  dispatch(setInfoReservation({name_tour:package_tour,children:child,adult:adult,infant:infant,id_reservation:id,customer:name}))
                                  dispatch(changeText('Check In'))
                              }}>
                                  <MoodOutlined/>
                                  <Typography className="txtNoChecking">Checking</Typography>
                              </div>
                              <div className="btnItemBad"
                                   onClick={()=>{
                                       dispatch(changeStateModal(true))
                                       dispatch(setInfoReservation({name_tour:package_tour,children:child,adult:adult,infant:infant,id_reservation:id,customer:name}))

                                       dispatch(changeText('No Show'))
                                   }}
                              >
                                  <MoodBadOutlined/>
                                  <Typography className="txtNoShow">No Show</Typography>
                              </div>

                          </div>
                      </TableCell>
                  </TableRow>
              </TableBody>
          </Table>
      </TableContainer>
  </div>
}
