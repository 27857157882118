import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer'
interface IModal {
    open: boolean,
    open_modal_form:boolean,
    type_tittle: string,
    message: string
}
const initialState: IModal = {
    open: false,
    open_modal_form:false,
    type_tittle: '',
    message: ''
};
const modalSlice = createSlice({
    name: 'backdrop',
    initialState,
    reducers: {
        changeStateModal: (state, {payload}: PayloadAction<boolean>) => {
            state.open = payload;
        },
        changeStateModalForm: (state, {payload}: PayloadAction<boolean> ) => {
            state.open_modal_form=payload
        },
        changeText: (state, {payload}: PayloadAction<string> ) => {
            state.type_tittle=payload
        },
    },
});

export const {changeStateModal,changeStateModalForm,changeText} = modalSlice.actions
export const ModalReducer = modalSlice.reducer
export const ModalSelector = (state: RootState) => state.modal
