import React, {useEffect, useRef, useState} from "react";
import {Button, IconButton, InputBase, Paper, Stack, Typography} from "@mui/material";
import {MoodBadOutlined, MoodOutlined, Search} from "@mui/icons-material";
import {useAppDispatch,useSelector} from "../../store/store";
import {
    setFiltersByChecking,
    setFiltersByNoShow,
    reservationFilterSelector,
    reservationSearch,
    setFiltersKey,
    ReservationSelector,
    sendReport
} from "../../store/reservation/reservation"
import { debounce } from "lodash";
import { changeStateModalForm} from "../../store/modal/modal";
import AddIcon from '@mui/icons-material/Add';
import {IReservationGet} from "../../models/reservation";
import {useNavigate} from "react-router-dom";

interface IFilter {
    isSale?: boolean;
    isVerifySale?:boolean;
    isHome?:boolean
}

export const Filter=(props:IFilter)=> {
    const dispatch = useAppDispatch();
    const navigate=useNavigate();
    const [isDisabled,setDisabled]=useState(true);
    const {filter_by_checking,filter_by_no_show,filter_key} = useSelector(reservationFilterSelector);
    const debouncedSearch = useRef(
        debounce(async (value:string) => {

                   dispatch(setFiltersKey(value))
                   dispatch(reservationSearch({client:value,isSale:props.isSale??false}))


        }, 800)
    ).current;
    const {reservations,loading_reservations} = useSelector(ReservationSelector);
    const isDataNull=reservations.some((reservation:IReservationGet) => reservation.Reserva === null);
    async function handleChange(value: string) {
        debouncedSearch(value);
    }

    useEffect(() => {

        if(isDataNull && loading_reservations===false){
            setDisabled(true)
        }else {
            if(reservations.length>0 && !loading_reservations && isDataNull===true){
                setDisabled(true)
            }
            if(isDataNull===false){
                setDisabled(false)
            }
        }
    },[loading_reservations,reservations,isDataNull]);
    return <>
        {
            !props.isVerifySale && <Paper
            className="inpSearch"
            component="form"
            sx={{p: '2px 4px', display: 'flex', alignItems: 'center', mt: 4}}
            >
            <InputBase
            sx={{ml: 4, flex: 1,}}
            onChange={(event)=>{
            handleChange(event.target.value).then()
        }}

            placeholder="Buscar Cliente..."
            inputProps={{'aria-label': 'Buscar Cliente...'}}
            />
            <IconButton type="button" sx={{p: '10px', mr: 0}} aria-label="search" className="btnSearch">
            <Search/><Typography> Buscar</Typography>
            </IconButton>
            </Paper>
        }


        <div className="contFiltro">
            <Stack direction="row" spacing={2} className="stack">
                {
                    props.isHome && <>
                        <Typography variant="body2">Filtrar por:</Typography>
                        <Button className={filter_by_checking?'btnHappy':'btnNeutral'}
                                onClick={()=>{
                                    dispatch(setFiltersByChecking())
                                    dispatch(reservationSearch({client:filter_key,isSale: !props.isSale}))
                                }} variant="outlined" size="small"
                                startIcon={<MoodOutlined/>}>Checking</Button>
                        <Button className={filter_by_no_show?'btnBad':'btnNeutral'}
                                onClick={()=>{
                                    dispatch(setFiltersByNoShow())
                                    dispatch(reservationSearch({client:filter_key,isSale: !props.isSale}))
                                }}  variant="outlined" size="small" startIcon={<MoodBadOutlined/>}>No
                            show</Button>
                        <Button
                            disabled={isDisabled}
                            onClick={()=>{
                                navigate("../verify-sales", {replace: true})
                            }}
                            variant="contained"
                        >Verificar Reporte</Button>
                    </>
                }

                {
                    props.isSale &&  <Button variant="contained" startIcon={<AddIcon />}
                    onClick={()=>{
                        dispatch(changeStateModalForm(true))
                    }}
                    >
                         Venta
                    </Button>
                }
                {
                    props.isVerifySale &&  <Button
                        disabled={isDisabled}
                        onClick={()=>{
                            dispatch(sendReport({}))
                        }}
                        variant="contained"
                    >Enviar Reporte</Button>
                }
            </Stack>
        </div>
    </>
}
