import React ,{useEffect} from 'react';
import {
    Typography,
    Divider,
    Container,
} from '@mui/material';
import {Filter} from '../../components/filter/filter';
import {Reservation} from '../../components/reservation/reservation'
import {Footer} from "../../components/footer/footer"
import {useAppDispatch,useSelector} from "../../store/store";
import {getReservationPrev, getReservationToday, ReservationSelector} from "../../store/reservation/reservation";
import {getPaymentTypes} from "../../store/paymentTypes/paymentTypes";
import {IReservationGet,IPrevReservation} from "../../models/reservation";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
interface Column {
    id: 'tour' | 'adult' | 'child' | 'infant' | 'noShowA'|'noShowN'|'noShowI';
    label: string;
    minWidth?: number;
    align?: 'right';
}
function createData(
    tour:string,
    adult:number,
    child:number,
    infant:number,
    noShowA:number,
    noShowN:number,
    noShowI:number,
): IPrevReservation {
    return { tour,adult,child,infant, noShowA, noShowN, noShowI};
}

function PrevSale() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getReservationPrev({}))
    }, []);

    const {loading_reservations,reservation_prev} = useSelector(ReservationSelector);
    const today=new Intl.DateTimeFormat('es-ES', { weekday: "short",
        day: "2-digit",
        month: "short",
        year: 'numeric'
    }).format(new Date());
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const columns: Column[] = [
        {
          id: 'tour',
          label: 'Paquete',
          minWidth: 170
        },
        { id: 'adult',
          label: 'Adultos',
          minWidth: 100
        },
        {
            id: 'child',
            label: 'Niños',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'infant',
            label: 'Infates',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'noShowA',
            label: 'No Show A',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'noShowN',
            label: 'No Show N',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'noShowI',
            label: 'No Show I',
            minWidth: 170,
            align: 'right',
        },
    ];

    const rows = [
        createData('Paquete 1', 10, 8, 2,2,1,0),

    ];
    return (
        <>

            <Container maxWidth="md" className="containerMD mb-8">
                    <Filter isVerifySale={true}/>
                <Divider variant="middle" className="dividerBottom"/>
                <div className="">
                    <Typography variant="h6" className="titulo" sx={{fontFamily:'AshleyMedium'}}>Previsualización {today}</Typography>
                    {
                        loading_reservations? <Stack  spacing={2} direction="row">
                            <CircularProgress sx={{  position:'absolute',left:0,right:0,top:0,bottom:0,margin:'auto'}} color="primary" />
                        </Stack>  : <Paper sx={{ width: '100%' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ top: 57, minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reservation_prev
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row:IPrevReservation) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.tour}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[ 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={100}
                                page={page}
                                onPageChange={()=>console.log('')}
                                onRowsPerPageChange={()=>console.log('')}
                            />
                        </Paper>
                    }
                </div>

                <Box sx={{  m: 1, p: 3,position:'fixed',bottom: '75px',right: '-25px'  }} onClick={()=>{
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }}>
                    <Fab size="medium" color="primary" aria-label="add" >
                        <KeyboardArrowUpOutlinedIcon />
                    </Fab>
                </Box>
            </Container>
            <Footer></Footer>


        </>
    );
}

export default PrevSale;
