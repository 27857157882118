import React,{FC,useEffect,useState} from "react";
import {
    Button,
    Typography,
    Modal,
    Container,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem, TextField, Grid, Divider
} from "@mui/material";
import {contained, outlined} from "../../assets/styles/main";
import {AxiosError} from "axios";
import {unwrapResult} from "@reduxjs/toolkit";
import {Add, Check, Close, MicNone } from "@mui/icons-material";
import {useSelector} from "react-redux";
import { changeStateModalForm, ModalSelector} from "../../store/modal/modal";
import { TourSelector} from "../../store/tours/tours";
import {InitialAddReservation,reservationAdd,ReservationSelectorError,ReservationSelector} from "../../store/reservation/reservation";
import {useAppDispatch} from "../../store/store";
import {getTours} from "../../store/tours/tours";
import {getPaymentTypes,getPaymentSelector,getCurrencies} from "../../store/paymentTypes/paymentTypes";
import {IAddReservation, ITours,IAddDetailReservation,IToursGet} from '../../models/reservation';
import {IPaymentGet,ICurrencieGet}       from '../../models/paymentTypes';
import FormHelperText from '@mui/material/FormHelperText';
import {useFormik} from 'formik';
import LinearProgress from '@mui/material/LinearProgress';
interface IModal{
    status?:'No show' | 'Check In',
    function_accept?:(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    function_deny?:(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}
export const ModalForm:FC<IModal>=({status,function_accept,function_deny})=>{
    const modalSelector = useSelector(ModalSelector);
    const tourSelector = useSelector(TourSelector);
    const reservationErrorSelector = useSelector(ReservationSelectorError);
    const {loading_reservations} = useSelector(ReservationSelector);
    const {paymentTypes,loading,currencies} = useSelector(getPaymentSelector);
    const [TotalPrice, setTotalPrice] = useState(0);
    const {open_modal_form} = modalSelector;
    const {loading_tours,tours} = tourSelector;
    const {errors_reservation} = reservationErrorSelector;

    useEffect(() => {
        if(open_modal_form){
            dispatch(getTours({}))
            dispatch(getPaymentTypes({}))
            dispatch(getCurrencies({}))
        }
    }, [open_modal_form]);

    const dispatch = useAppDispatch();
    const today=new Intl.DateTimeFormat('es-ES', { weekday: "short",
        day: "2-digit",
        month: "short",
        year: 'numeric'
    }).format(new Date());

    const {
        setFieldValue,
        values,
        handleChange,
        submitForm,
        handleBlur,
    } = useFormik<IAddReservation>({
        initialValues: InitialAddReservation,
        onSubmit: (values: IAddReservation, formikHelpers) => {
            dispatch(reservationAdd({cliente:values.name,idTipopago:values.idTipopago,detallesVenta:values.package_tours})).then(unwrapResult).then((result: any) => {
                setFieldValue(`package_tours`,[{
                    id:0,
                    adultos:1,
                    ninos:0,
                    infantes:0,
                    precioAdulto:0,
                    precioNino:0,
                }])
                setFieldValue(`name`,'')
                setFieldValue(`idTipopago`,0)
            }).catch((result: AxiosError) => {
                // @ts-ignore
                dispatch(openNotifier({variant: "error", open: true, message: result?.message}))
            })

            // dispatch(reservationAdd({cliente:'',detallesVenta:[]}))

        },
    })
    useEffect(() => {
        if(open_modal_form){
            let totalPrice=values.package_tours.reduce((totalPrice, tour) => (totalPrice+((tour.adultos*tour.precioAdulto)+(tour.ninos*tour.precioNino))), 0);
            setTotalPrice(totalPrice)
        }

    }, [values.package_tours]);

    return  <Modal
        open={open_modal_form}
        className="ModalAdd"
    >
        <Container maxWidth="md">
            <Box mt={8} sx={{padding: '0 30px;'}}>
                <div className="DFlex">
                    <Typography id="modal-modal-title " className="strong f-25" variant="h6" component="h2">
                        Reserva de ultimo momento
                    </Typography>
                    <Typography id="modal-modal-title " className="strong f-25" variant="h6" component="h2">
                         &nbsp;{ today.charAt(0).toUpperCase() + today.slice(1)}
                    </Typography>
                </div>
                <FormControl variant="standard" sx={{mt: 4, minWidth: '100%'}}>
                    <div className="contMicrofono">
                        <TextField
                            id="name_customer"
                            label="Nombre"
                            onChange={handleChange}
                            error={Boolean((errors_reservation?.errores?.cliente?.[0]))}
                            helperText={Boolean(errors_reservation?.errores?.cliente?.[0])? errors_reservation?.errores?.cliente?.[0] : ''}
                            onBlur={handleBlur}
                            name={'name'}
                            variant="standard"
                            className="inputMicron" sx={{mt: 4, minWidth: '100%'}}
                        />
                        {/*<Button variant="contained" endIcon={<MicNone/>}></Button>*/}
                    </div>
                </FormControl>
                <FormControl variant="standard" sx={{mt: 4, minWidth: '100%'}} >
                    <InputLabel id={`id-label-payment-method`}>Seleccionar Metodo de pago</InputLabel>

                    {
                        loading ? <LinearProgress /> :  <Select
                            labelId={`id-label-payment-method`}
                            id={`id-label-payment-method-select`}
                            name={`select-id-label-payment-method`}
                            value={values.idTipopago}
                            onChange={(event, value) => {
                                setFieldValue(`idTipopago`, event.target.value)

                            }}
                            label="Selecciona metodo de pago"
                        >
                            {
                                !loading && paymentTypes.length>0 && paymentTypes.map((paymentType:IPaymentGet)=>
                                    <MenuItem value={paymentType.idTipopago} key={paymentType.idTipopago}>{paymentType.name}</MenuItem>
                                )
                            }


                        </Select>

                    }

                </FormControl>

                <FormControl variant="standard" sx={{mt: 4, minWidth: '100%'}} >
                    <InputLabel id={`id-label-currency-type`}>Seleccionar Tipo de moneda</InputLabel>

                    {
                        loading ? <LinearProgress /> :  <Select
                            labelId={`id-label-currency-type`}
                            id={`id-label-currencie-type`}
                            name={`currency_id`}
                            value={values.currency_id}
                            onChange={(event, value) => {
                                setFieldValue(`currency_id`, event.target.value)

                            }}
                            label="Selecciona tipo de moneda"
                        >
                            {
                                !loading && paymentTypes.length>0 && currencies.map((currencieType:ICurrencieGet)=>
                                    <MenuItem value={currencieType.id} key={currencieType.id}>{currencieType.sigla}</MenuItem>
                                )
                            }


                        </Select>

                    }

                </FormControl>
                {values.package_tours.length > 0 &&
                    values.package_tours.map((tour: IAddDetailReservation, index: number) => (
                        <FormControl key={index} variant="standard" sx={{mt: 4, minWidth: '100%'}}>

                            {
                                values.package_tours.length > 0 &&        <Divider className="mt-2">
                                    <Button variant="outlined"
                                            key={`button-remove${index}`}
                                            disabled={values.package_tours.length===1}
                                            onClick={() => {
                                                setFieldValue('package_tours', values.package_tours.filter
                                                ((value, innerIndex) => innerIndex !== index
                                                ))
                                            }} size="small" color="secondary" style={{textTransform:'inherit',borderRadius:'50px'}}>
                                        <Add fontSize="inherit"/> Eliminar Paquete
                                    </Button>
                                </Divider>

                            }

                            <InputLabel id={`id-label-package-${index}`}>Seleccionar paquete</InputLabel>

                            {
                                loading_tours ? <LinearProgress /> :  <FormControl  error={Boolean(errors_reservation.errores?.[`detallesVenta.${index}.idTour`])}><Select
                                    labelId={`id-label-package-${index}`}
                                    id={`select-package-tour-${index}`}
                                    name={`select-package-tour-${index}`}

                                    value={values.package_tours[index].idTour}
                                    onChange={(event, value) => {
                                        setFieldValue(`package_tours.${index}.idTour`, event.target.value)
                                        setFieldValue(`package_tours.${index}.precioAdulto`, tours.find((find:IToursGet)=>find.id===event.target.value)?.precioAdulto)
                                        setFieldValue(`package_tours.${index}.precioNino`,tours.find((find:IToursGet)=>find.id===event.target.value)?.precioNino)

                                    }}
                                    label="Seleccione paquete"
                                >
                                    {
                                        !loading_tours && tours.length>0 && tours.map((package_tour:IToursGet)=>
                                            <MenuItem value={package_tour.id} key={package_tour.id}>{package_tour.nombreTour}</MenuItem>
                                        )
                                    }


                                </Select>
                                    {
                                        Boolean(errors_reservation.errores?.[`detallesVenta.${index}.idTour`]) && <FormHelperText>
                                            {errors_reservation.errores?.[`detallesVenta.${index}.idTour`]}
                                        </FormHelperText>
                                    }
                                </FormControl>
                            }


                            <Grid container sx={{mt: 5}}>
                                <Grid item xs>
                                    <div className="grid">
                                        <Typography>Adultos:</Typography>
                                        <TextField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.package_tours[index].adultos}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            name={`package_tours.${index}.adultos`}
                                            key={`adults-${index}`}
                                            id={`packege-${index}-adultos`}
                                            variant="outlined"
                                            type="number"/>
                                    </div>
                                </Grid>
                                <Divider orientation="vertical" flexItem/>
                                <Grid item xs>
                                    <div className="grid">
                                        <Typography>Niños:</Typography>
                                        <TextField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.package_tours[index].ninos}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            name={`package_tours.${index}.ninos`}
                                            key={`child-${index}`}
                                            id={`packege-${index}-ninos`}
                                            variant="outlined"
                                            type="number"/>
                                    </div>
                                </Grid>
                                <Divider orientation="vertical" flexItem/>
                                <Grid item xs>
                                    <div className="grid">
                                        <Typography>Infantes:</Typography>
                                        <TextField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.package_tours[index].infantes}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            name={`package_tours.${index}.infantes`}
                                            key={`infant-${index}`}
                                            id={`packege-${index}-infantes`}
                                            variant="outlined"
                                            type="number"/>

                                    </div>
                                </Grid>
                                <Divider orientation="vertical" flexItem/>
                            </Grid>
                        </FormControl>
               ))}

                <FormControl variant="standard" sx={{mt: 4, minWidth: '100%'}}>
                    <Divider className="mt-2">
                        <Button variant="outlined"
                                onClick={() => setFieldValue('package_tours', values.package_tours.concat(
                            {
                                idTour:0,
                                adultos:1,
                                ninos:0,
                                infantes:0,
                                precioAdulto:0,
                                precioNino:0,
                            }
                        ))} size="small" color="secondary" style={{textTransform:'inherit',borderRadius:'50px'}}>
                            <Add fontSize="inherit"/> Añadir otra Paquete
                        </Button>
                    </Divider>
                    <div className="DFlex totalTXT noTextShadow">
                        <div>
                            <Typography>&nbsp;</Typography>
                        </div>
                        <div>
                            <Typography>Total: ${TotalPrice} us</Typography>
                        </div>
                    </div>
                </FormControl>
                <div className="btnFooter noTextShadow">
                    <div className="DFlex">
                        <Button sx={outlined}
                                onClick={()=>{
                                    dispatch(changeStateModalForm(false))
                                    setFieldValue(`package_tours`,[{
                                        id:0,
                                        adultos:1,
                                        ninos:0,
                                        infantes:0,
                                        precioAdulto:0,
                                        precioNino:0,
                                    }])
                                    setFieldValue(`name`,'')
                                }}
                                className="btn-footer" variant="outlined" size="small"
                                startIcon={<Close/>}>Cancelar</Button>
                        <Button sx={contained}
                                disabled={loading_reservations}
                                className="btn-footer"
                                onClick={submitForm}
                                variant="contained"
                                size="small"
                                startIcon={<Check/>}>Aceptar</Button>
                    </div>
                </div>
            </Box>
        </Container>
    </Modal>
}
