import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import {RootState} from '../rootReducer'
import {
    IReservationState,
    IAddReservation,
    IReservationGet,
    IErrorReservation,
    IPrevReservation,
    IReservationShow,
    IAddDetailReservation, IPostReservation
} from "../../models/reservation";
import {closeBackdrop, openBackdrop} from "../backdrop/backdrop";
import {changeStateModal,changeStateModalForm} from "../../store/modal/modal";
import {openNotifier} from "../notifier/notifier";

const api = process.env.REACT_APP_API_URL

export const initialStatReservation: IReservationState = {
    reservations:[],
    loading:false,
    reservation:{
        customer:'',
        id_reservation:0,
        children:0,
        adult:0,
        infant:0,
        name_tour:''
    },
    prev_reservation:[],
    filter:{
        parameter:{
            by_checking:false,
            by_no_show:false
        },
        search:''
    },
    errors:false
}
export const InitialAddReservation:IAddReservation={
    package_tours:[{
        idTour:0,
        adultos:1,
        ninos:0,
        infantes:0,
        precioNino:0,
        precioAdulto:0
    }],
    name:'',
    idTipopago:0,
    currency_id:0,
    balance:0,
}
export const InitialChangeStatusReservation:IPostReservation={
    estado: '',
    flagUpdate: false,
    new_intAdulto: 0,
    new_intNinos: 0,
    new_intInfante: 0
}

export const getReservationToday = createAsyncThunk<IReservationGet[],
    {typeSale:boolean },
    {
        rejectValue: unknown
    }>(
    'reservation/getToday',
    async (data, thunkApi) => {
        try {
            const url_type_request=data.typeSale?'getVentas':'getReservas';
            const dayParts = new Intl.DateTimeFormat().formatToParts(new Date());
            const todayFormat=`${dayParts[4].value}-${dayParts[2].value}-${dayParts[0].value}`;
            const response = await axios.get(`${api}/${url_type_request}?=${todayFormat}`);
            thunkApi.dispatch(closeBackdrop())
            return response.data;
        } catch (err) {
            console.log(err)
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message}))
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const reservationSearch = createAsyncThunk<IReservationGet[],
    {client:string,isSale:boolean },
    {
        rejectValue: unknown
    }>(
    'reservation/search',
    async (data, thunkApi) => {
        const dayParts = new Intl.DateTimeFormat().formatToParts(new Date());
        const todayFormat=`${dayParts[4].value}-${dayParts[2].value}-${dayParts[0].value}`;
        // @ts-ignore
        const filter_by=thunkApi.getState().reservation.filter.parameter.by_checking?1:(thunkApi.getState().reservation.filter.parameter.by_no_show?3:'');
        try {

            // @ts-ignore
            const response = await axios.get(`${api}/${data.isSale?'getVentas':'getReservas'}?${data.client!=='' ? '&cliente=' + data.client : ''}${(filter_by!==''&& !data.isSale) ? '&estado=' + filter_by : ''}&fecha=${todayFormat}`);
            // @ts-ignore
            return response.data;
        } catch (err) {
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.error}))
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const reservationChangeStatus = createAsyncThunk<unknown,
    {status:number,id:string,flagUpdate:boolean,new_intAdulto: number,
        new_intNinos: number,
        new_intInfante: number},
    {
        rejectValue: unknown
    }>(
    'reservation/changeStatus',
    async (data, thunkApi) => {

        try {
            const response = await axios.put(`${api}/setEstadoReserva/${data.id}`,{estado:data.status,
                flagUpdate: data.flagUpdate,
                new_intAdulto: data.new_intAdulto,
                new_intNinos: data.new_intNinos,
                new_intInfante: data.new_intInfante
            });
            thunkApi.dispatch(changeStateModal(false))
            thunkApi.dispatch(getReservationToday({typeSale:false}))
            thunkApi.dispatch(openNotifier({open:true,variant:"success",message:response.data?.message}))

            return response.data;
        } catch (err) {
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.error}))
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const getReservationPrev = createAsyncThunk<IPrevReservation[],
    {},
    {
        rejectValue: unknown
    }>(
    'reservation/reservationPrev',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/getPreReport`);
            thunkApi.dispatch(closeBackdrop())
            return response.data;
        } catch (err) {
            console.log(err)
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message}))
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const reservationAdd = createAsyncThunk<unknown,
    {cliente:string,detallesVenta:IAddDetailReservation[],idTipopago:number},
    {
        rejectValue: unknown
    }>(
    'reservation/add',
    async (data, thunkApi) => {
        try {
            const response = await axios.post(`${api}/agregarVenta`,{cliente:data.cliente,balance:0,detallesVenta:data.detallesVenta,idTipopago:data.idTipopago});
            thunkApi.dispatch(changeStateModalForm(false))
            thunkApi.dispatch(getReservationToday({typeSale:true}))
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"success",message:response.data?.Message}))
            return response.data;
        } catch (err) {
            // @ts-ignore
            thunkApi.dispatch(fillError(err?.response?.data))
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message}))
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const sendReport = createAsyncThunk<unknown,
    {},
    {
        rejectValue: unknown
    }>(
    'reservation/sendReport',
    async (data, thunkApi) => {
        try {
            const response = await axios.get(`${api}/sendReport`);
            thunkApi.dispatch(getReservationToday({typeSale:true}))
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"success",message:response.data?.Message??'Reporte enviado con éxito'}))
            return response.data;
        } catch (err) {
            // @ts-ignore
            thunkApi.dispatch(fillError(err?.response?.data))
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message??'Problema de conexión, Contacte a soporte'}))
            return thunkApi.rejectWithValue('error')
        }
    }
)

export const reservationSlice = createSlice({
    initialState:initialStatReservation,
    name: 'reservation',
    extraReducers: builder => {
        builder
            .addCase(getReservationToday.pending, state => {
                state.loading = true
            })
            .addCase(getReservationToday.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.reservations = payload.data
                state.loading = false
            })
            .addCase(getReservationToday.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(reservationSearch.pending, state => {
                state.loading = true
            })
            .addCase(reservationSearch.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.reservations = payload?.data;
                state.loading = false
            })
            .addCase(reservationSearch.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(reservationChangeStatus.pending, state => {
                state.loading = true
            })
            .addCase(reservationChangeStatus.fulfilled, (state, {payload}) => {
                state.loading = false
                state.reservation!.name_tour = '';
                state.reservation!.customer ='';
                state.reservation!.id_reservation =0;
                state.reservation!.adult =0;
                state.reservation!.children =0;
                state.reservation!.infant =0;
            })
            .addCase(reservationChangeStatus.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(reservationAdd.pending, state => {
                state.loading = true
            })
            .addCase(reservationAdd.fulfilled, (state, {payload}) => {
                state.loading = false
                state.errors=false
            })
            .addCase(reservationAdd.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(sendReport.pending, state => {
                state.loading = true
            })
            .addCase(sendReport.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(sendReport.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(getReservationPrev.pending, state => {
                state.loading = true
            })
            .addCase(getReservationPrev.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.prev_reservation = payload.data
                state.loading = false
            })
            .addCase(getReservationPrev.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {
        setFiltersByChecking: (state) => {
            state.filter.parameter.by_checking = !state.filter.parameter.by_checking;
            state.filter.parameter.by_no_show = false;
        },
        fillError: (state, action: PayloadAction<IErrorReservation>) => {
            state.errors = {...action.payload};
        },
        setFiltersByNoShow: (state) => {
            state.filter.parameter.by_no_show = !state.filter.parameter.by_no_show;
            state.filter.parameter.by_checking =false;
        },
        setInfoReservation: (state, action: PayloadAction<IReservationShow>) => {
            state.reservation!.name_tour = action.payload.name_tour;
            state.reservation!.customer =action.payload.customer;
            state.reservation!.id_reservation =action.payload.id_reservation;
            state.reservation!.adult =action.payload.adult;
            state.reservation!.children =action.payload.children;
            state.reservation!.infant =action.payload.infant;
        },
        setFiltersKey: (state, action: PayloadAction<string>) => {
            state.filter.search = action.payload;
        },

    },
})

export const reservationFilterSelector = (state: RootState) => {
    return {
        filter_by_checking:state.reservation.filter.parameter.by_checking,
        filter_by_no_show:state.reservation.filter.parameter.by_no_show,
        filter_key:state.reservation.filter.search,
    }
};
export const ReservationSelector = (state: RootState) => {
    return {
        loading_reservations:state.reservation.loading,
        reservations:state.reservation.reservations,
        reservation_prev:state.reservation.prev_reservation
    }
};
export const ReservationSelectorError = (state: RootState) => {
    return {
        errors_reservation:state.reservation.errors,
    }
};
export const reservationInfoSelector = (state: RootState) => {
    return {
        reservation_customer:state.reservation.reservation.customer,
        reservation_id:state.reservation.reservation.id_reservation,
        reservation_tour:state.reservation.reservation.name_tour,
        reservation_adult:state.reservation.reservation.adult,
        reservation_children:state.reservation.reservation.children,
        reservation_infant:state.reservation.reservation.infant,
    }
};
export const {
setFiltersByChecking,
setFiltersByNoShow,
setFiltersKey,
    fillError,
setInfoReservation
} = reservationSlice.actions;
export const ReservationReducer = persistReducer({
    storage:storageSession,
    key: 'reservation',
    whitelist: ['access_token', 'user'],
    blacklist:['open_dialog']
}, reservationSlice.reducer)
