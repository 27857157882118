import React from 'react';
import {useSelector} from 'react-redux';
import {BackdropSelector} from "../../store/backdrop/backdrop";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropComponent = () => {
    const backdropSelector = useSelector(BackdropSelector);
    const {open} = backdropSelector;


    return <Backdrop style={{zIndex: '99'}}

                     open={open}

    >
        <CircularProgress style={{color:'#fff'}} />
    </Backdrop>;
};

export default BackdropComponent;
