import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer'


interface backdrop {
    open: boolean
}

const initialState: backdrop = {
    open: false,
};
const backdropSlice = createSlice({
    name: 'backdrop',
    initialState,
    reducers: {
        openBackdrop: (state) => {
            state.open = true;
        },
        closeBackdrop: (state ) => {
            state.open=initialState.open
        },
    },
});

export const {openBackdrop,closeBackdrop} = backdropSlice.actions
export const BackdropReducer = backdropSlice.reducer
export const BackdropSelector = (state: RootState) => state.backdrop
