import React ,{useEffect} from 'react';
import {
    Typography,
    Divider,
    Container,
} from '@mui/material';
import {Filter} from '../../components/filter/filter';
import {Reservation} from '../../components/reservation/reservation'
import {Footer} from "../../components/footer/footer"
import {useAppDispatch,useSelector} from "../../store/store";
import {getReservationToday, ReservationSelector} from "../../store/reservation/reservation";
import {getPaymentTypes} from "../../store/paymentTypes/paymentTypes";
import {IReservationGet} from "../../models/reservation";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import PullToRefresh from 'react-simple-pull-to-refresh';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
function Sale() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getReservationToday({typeSale:true}))
    }, []);
 const handleRefresh=()=>{
     dispatch(getReservationToday({typeSale:true}))
}
    const {loading_reservations,reservations} = useSelector(ReservationSelector);
    const today=new Intl.DateTimeFormat('es-ES', { weekday: "short",
        day: "2-digit",
        month: "short",
        year: 'numeric'
    }).format(new Date());
    return (
        <>

            <Container maxWidth="md" className="containerMD mb-8">
                    <Filter isSale={true}/>
                <Divider variant="middle" className="dividerBottom"/>
                <PullToRefresh
                    isPullable={true}
                    pullingContent={''}
                    onRefresh={async () => handleRefresh()}>
                <div className="">
                    <Typography variant="h6" className="titulo" sx={{fontFamily:'AshleyMedium'}}>Ventas {today}</Typography>
                    {
                        loading_reservations? <Stack  spacing={2} direction="row">
                            <CircularProgress sx={{  position:'absolute',left:0,right:0,top:0,bottom:0,margin:'auto'}} color="primary" />
                        </Stack>  :<div>

                            {
                                reservations.length>0?
                                reservations.map((reservation: IReservationGet, index: number
                                ) => <Reservation
                                    key={index}
                                    comentarios={reservation.comentarios}
                                    canalDistribucion={reservation.canalDistribucion}
                                    id={reservation.idReserva_agencia}
                                    index={index + 1}
                                    infant={reservation.Infantes}
                                    status={'1'}
                                    adult={reservation.Adultos}
                                    child={reservation.Ninos}
                                    package_tour={reservation.Tour}
                                    name={reservation.Cliente}
                                    payment_method={'card'}
                                    balance={reservation.Balance}/>
                            ):<span>No se encontraron Ventas</span>
                            }


                        </div>

                    }
                </div>
                </PullToRefresh>
                <Box sx={{  m: 1, p: 3,position:'fixed',bottom: '75px',right: '-25px'  }} onClick={()=>{
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }}>
                    <Fab size="medium" color="primary" aria-label="add" >
                        <KeyboardArrowUpOutlinedIcon />
                    </Fab>
                </Box>
            </Container>
            <Footer></Footer>


        </>
    );
}

export default Sale;
