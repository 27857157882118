import { combineReducers } from '@reduxjs/toolkit'
import {AuthReducer} from './auth/auth';
import {ModalReducer} from './modal/modal';
import {ReservationReducer} from './reservation/reservation';
import {NotifierReducer} from './notifier/notifier';
import {TourReducer} from './tours/tours';
import {BackdropReducer} from './backdrop/backdrop'
import {PaymentTypesReducer} from './paymentTypes/paymentTypes'
export const rootReducer = combineReducers({
    auth:AuthReducer,
    modal:ModalReducer,
    notifier:NotifierReducer,
    reservation:ReservationReducer,
    tours:TourReducer,
    backdrop:BackdropReducer,
    paymentType:PaymentTypesReducer
})

export type RootState = ReturnType<any>

export default rootReducer
