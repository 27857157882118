import React, {ReactNode} from 'react';
import {Header} from "../header/header"

interface layoutComponent {
    children: ReactNode
}

export const LayoutAuthenticated = ({children}: layoutComponent) => {
    return (
        <>
             <Header/>
            <main>{children}</main>
        </>
    )
}
