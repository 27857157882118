import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import {RootState} from '../rootReducer'
import {IErrorsLogin,AuthState,IAuth} from "../../models/login";
import {openNotifier} from "../notifier/notifier";
const api = process.env.REACT_APP_API_URL
export const initialStateAuth: AuthState = {
    access_token: null,
    user: {
        name: "",
        email:"",
        id:0,
        enterprises:""
    },
    errors: null,
    loading: false,
    open_dialog:false
}

export const auth = createAsyncThunk<IAuth,
    { username: string, password: string },
    {
        rejectValue: unknown
    }>(
    'app/auth',
    async (data, thunkApi) => {
        try {
            // @ts-ignore
            const response = await axios.post(`${api}/login`, {strEmail:data.username,strPassword:data.password});
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"success",message:`Welcome ${response.data?.user?.strNombre}`}))
            return response.data;
        } catch (err) {
            // @ts-ignore
            console.log(err?.response?.data?.error)
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message??'Problema de conexión, Contacte a soporte'}))
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const logout = createAsyncThunk<unknown,
    {},
    {
        rejectValue: unknown
    }>(
    'app/logout',
    async (data, thunkApi) => {
        try {
            // @ts-ignore
            const response = await axios.get(`${api}/logout`);
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"success",message:`See you soon`}))
            return response.data;
        } catch (err) {
            // @ts-ignore
            console.log(err?.response?.data?.error)
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message??'Problema de conexión, Contacte a soporte'}))
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const authSlice = createSlice({
    initialState:initialStateAuth,
    name: 'auth',
    extraReducers: builder => {
        builder
            .addCase(auth.pending, state => {
                state.loading = true
            })
            .addCase(auth.fulfilled, (state, {payload}) => {
                const {accessToken, user} = payload
                state.access_token = accessToken
                state.user.enterprises = user.enterprises
                state.user.name=user.strNombre
                state.user.email=user.strEmail
                state.user.id=user.idContador
                state.loading = false
            })
            .addCase(auth.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(logout.pending, state => {
                state.loading = true
            })
            .addCase(logout.fulfilled, (state, {payload}) => {
                state.access_token = null
                state.user.enterprises = ''
                state.user.name=''
                state.user.email=''
                state.user.id=0
                state.loading = false
            })
            .addCase(logout.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {
        fillError: (state, action: PayloadAction<IErrorsLogin>) => {
            state.errors = {...action.payload};
        },
        openDialog:(state,action: PayloadAction<boolean>)=>{
            state.open_dialog= action.payload;
        },
    },
})

export const authSelector = (state: RootState) => state.auth;

export const {

} = authSlice.actions;
export const AuthReducer = persistReducer({
    storage:storageSession,
    key: 'auth',
    whitelist: ['access_token', 'user'],
    blacklist:['open_dialog']
}, authSlice.reducer)
