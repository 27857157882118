import React, {useEffect, useState} from 'react';
import {Button} from "@mui/material";
import {Add, Search, BookOnline, PointOfSale} from "@mui/icons-material";
import {outlined, contained} from '../../assets/styles/main'
import {useSelector} from "react-redux";
import {ModalSelector, changeStateModalForm} from "../../store/modal/modal";
import {useAppDispatch} from "../../store/store";
import {useLocation, useNavigate} from 'react-router-dom';

export const Footer = () => {
    // const modalSelector = useSelector(ModalSelector);
    let location = useLocation();
    const navigate = useNavigate();
    const [pathName, setPathName] = useState('');
    useEffect(() => {
        setPathName(location.pathname)
    }, [location]);
    const dispatch = useAppDispatch();
    return <div className="btnFooter">
        <div className="DFlex">
            <Button sx={outlined}
                    className="btn-footer"
                    variant="outlined"
                    onClick={() => {
                        // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                        if (pathName !== '/home') {
                            navigate("../home", {replace: true})
                        }
                    }}
                    size="small"
                    startIcon={<BookOnline/>}>
                Reservas
            </Button>
            <Button sx={contained}
                    className="btn-footer"
                    variant="contained"
                    size="small"
                    startIcon={<PointOfSale/>}
                    onClick={() => {
                        if (pathName !== '/sales') {
                            navigate("../sales", {replace: true})
                        }
                        // dispatch(changeStateModalForm(true))
                    }
                    }
            >Ventas</Button>
        </div>
    </div>
}
