import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import {RootState} from '../rootReducer'
import {closeBackdrop, openBackdrop} from "../backdrop/backdrop";
import {openNotifier} from "../notifier/notifier";
const api = process.env.REACT_APP_API_URL
export interface ITours{
    id:number
    nombreTour:string,
    precioAdulto:number,
    precioNino:number
}
export interface IToursGet{
    loading_tours:boolean
    tours:ITours[]
}
const initialState: IToursGet = {
    tours:[],
    loading_tours:false
}

export const getTours = createAsyncThunk<ITours[],
    { },
    {
        rejectValue: unknown
    }>(
    'tour/getAll',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            const response = await axios.get(`${api}/getTours`);
            // @ts-ignore
            // thunkApi.dispatch(openNotifier({open:true,variant:"success",message:`Welcome ${response.data?.user?.name}`}))
            return response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore
            thunkApi.dispatch(fillError(err.response.data))
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:'wrong password or username'}))
            return thunkApi.rejectWithValue('error')
        }
    }
)
export const tourSlice = createSlice({
    initialState,
    name: 'tour',
    extraReducers: builder => {
        builder
            .addCase(getTours.pending, state => {
                state.loading_tours = true
            })
            .addCase(getTours.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.tours = payload.data
                state.loading_tours = false
            })
            .addCase(getTours.rejected, (state, action) => {
                state.loading_tours = false
                state.tours = []
            })
    },
    reducers: {
    },
})

export const TourSelector = (state: RootState) => state.tours;
export const TourReducer = persistReducer({
    storage:storageSession,
    key: 'tour',
}, tourSlice.reducer)
