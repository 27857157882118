import React from "react";
import {AppBar, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import {logout} from "../../store/auth/auth";
import {useAppDispatch} from "../../store/store";
import {useNavigate} from "react-router-dom";
export const Header=()=> {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useAppDispatch();
    const navigate=useNavigate();
    const openMenu = Boolean(anchorEl);
    return  <AppBar position="static" className="nav-gradiant">
        <Toolbar>

            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                LOGO
            </Typography>
            <div>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    color="inherit"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <AccountCircle/>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    id="menu-appbar"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}

                >
                    <MenuItem onClick={()=>{
                        dispatch(logout({}))
                        navigate("../login", { replace: true })
                    }}>Logout</MenuItem>
                </Menu>
            </div>
        </Toolbar>
    </AppBar>
}
