export const outlined = {
    border: '3px solid #8A3BBB',
    color: '#8A3BBB',
    borderRadius: '50px',
    fontFamily: 'AshleyMedium',
    textTransform: 'capitalize',
    fontSize: '18px',
    padding: '8px',
    width: '100%',
    height: '55px',
    margin: '0 5px'
};
export const contained = {
    background: '#8A3BBB',
    color: '#fff',
    borderRadius: '50px',
    fontFamily: 'AshleyMedium',
    textTransform: 'capitalize',
    fontSize: '18px',
    padding: '8px',
    width: '100%',
    height: '55px',
    margin: '0 5px'
}
export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    border: 'none',
};
