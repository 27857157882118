import React,{FC,useEffect} from "react";
import {Button, Card, Typography, Modal, TextField} from "@mui/material";
import {contained, outlined,style} from "../../assets/styles/main";
import {Check, Close} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {ModalSelector,changeStateModal,changeText} from "../../store/modal/modal";
import {useAppDispatch} from "../../store/store";
import {useFormik} from 'formik';
import {
    reservationInfoSelector,
    reservationChangeStatus,
    ReservationSelector, InitialAddReservation, InitialChangeStatusReservation, getReservationToday
} from "../../store/reservation/reservation";
import {IAddReservation, IPostReservation} from "../../models/reservation";
import {unwrapResult} from "@reduxjs/toolkit";
import {AxiosError} from "axios";


interface IModal{
   status?:'No show' | 'Check In',
   function_accept?:() => void,
   function_deny?:() => void
}

export const ModalComponent:FC<IModal>=({status,function_accept,function_deny})=>{
    const modalSelector = useSelector(ModalSelector);
    const {reservation_customer,reservation_id,reservation_tour,reservation_infant,reservation_children,reservation_adult} = useSelector(reservationInfoSelector);
    const {open,type_tittle} = modalSelector;
    const {loading_reservations} = useSelector(ReservationSelector);
    const dispatch = useAppDispatch();
    const {
        setFieldValue,
        setValues,
        values,
        handleChange,
        submitForm,
        handleBlur,
    } = useFormik<IPostReservation>({
        initialValues: InitialChangeStatusReservation,
        onSubmit: (values: IPostReservation, formikHelpers) => {
            if (type_tittle==='Check In'){
                if(values.new_intInfante!==reservation_infant || values.new_intAdulto!==reservation_adult ||values.new_intNinos!==reservation_children){
                    dispatch(reservationChangeStatus({id:reservation_id,status:1,new_intAdulto:values.new_intAdulto,new_intNinos:values.new_intNinos,new_intInfante:values.new_intInfante,flagUpdate:true}))

                } else {
                    dispatch(reservationChangeStatus({id:reservation_id,status:1,new_intAdulto:values.new_intAdulto,new_intNinos:values.new_intNinos,new_intInfante:values.new_intInfante,flagUpdate:false}))
                }
            }else{
                dispatch(reservationChangeStatus({id:reservation_id,status:3,new_intAdulto:values.new_intAdulto,new_intNinos:values.new_intNinos,new_intInfante:values.new_intInfante,flagUpdate:false}))
            }


        },
    })

    useEffect(() => {
       if(type_tittle==='Check In'){
           setValues({
               estado: "1",
               flagUpdate: false,
               new_intAdulto: reservation_adult,
               new_intNinos: reservation_children,
               new_intInfante: reservation_infant
           })
       }else {
           setValues({
               estado: "3",
               flagUpdate: false,
               new_intAdulto: reservation_adult,
               new_intNinos: reservation_children,
               new_intInfante: reservation_infant
           })
       }
    }, [type_tittle,reservation_id]);
    return             <Modal
    open={open}
    className="bg-modalGradient"
>
    <Card sx={style}>
        <Typography id="modal-modal-title " className="strong f-25" variant="h6" component="h2">
            ¿Estas seguro que deseas hacer {type_tittle} a {reservation_customer} del paquete "{reservation_tour}" ?
        </Typography>
        <div>
            {type_tittle==='Check In'?<>
                <Typography>Adultos:</Typography>
                <div className="DFlex">
                    <TextField
                        className="txtRight"
                        name={'new_intAdulto'}
                        value={values.new_intAdulto}
                        InputProps={{ inputProps: { min: 0 } }}
                        id='new_intAdulto'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        type="number"/>
                </div>
                <Typography>Niños:</Typography>
                <div className="DFlex">
                    <TextField
                        className="txtRight"
                        value={values.new_intNinos}
                        InputProps={{ inputProps: { min: 0 } }}
                        name='new_intNinos'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id='new_intNinos'
                        variant="outlined"
                        type="number"/>
                </div>
                <Typography>Infantes:</Typography>
                <div className="DFlex">
                    <TextField
                        className="txtRight"
                        value={values.new_intInfante}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{ inputProps: { min: 0 } }}
                        name='new_intInfante'
                        id='new_intInfante'
                        variant="outlined"
                        type="number"/>
                </div>
            </>:<div></div>}
        </div>
        <div className="DFlex">
            <Button sx={outlined}
                    onClick={()=>{
                        dispatch(changeStateModal(false))
                        dispatch(changeText(''))
                    }}
                    className="btn-modal"
                    disabled={loading_reservations}
                    variant="outlined" size="small"
                    startIcon={<Close/>}>Cancelar</Button>
            <Button sx={contained}
                    onClick={()=>{
                        submitForm().then()
                    }}
                    className="btn-modal"
                    disabled={loading_reservations}
                    variant="contained"
                    size="small"
                    startIcon={<Check/>}
            >{type_tittle}</Button>
        </div>
    </Card>
</Modal>
}
